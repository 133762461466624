<template>
  <div :class="$style.app">
    <VHeader :user="getUser"/>
    <main :class="$style.main">
      <router-view></router-view>
    </main>
    <VFooter/>
  </div>
</template>

<script>
import VHeader from '@/components/VHeader.vue';
import VFooter from '@/components/VFooter.vue';
import UserService from '@/services/user.service';

export default {
  name: 'App',
  components: {
    VHeader,
    VFooter,
  },
  data() {
    return {
      user: {},
    };
  },

  computed: {
    getUser() {
      return this.user.data;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/matches');
    }
    UserService.getUserBoard().then(
      (response) => {
        this.user = response.data;
      },
      (error) => {
        // eslint-disable-next-line
        this.content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      },
    );
  },
};
</script>

<style lang="scss" module>
.main {
  padding: 45px 0 70px;
  min-height: calc(100vh - 425px);
  transition: padding-top .3s;
  will-change: padding-top;
}
</style>
