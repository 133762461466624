/* eslint-disable */

import axios from 'axios';
import authHeader from '@/services/auth-header';

const API_URL = process.env.VUE_APP_API_URL;

class AuthService {
  login(user) {
    return axios
      .post(API_URL + '/auth/login', {
        email: user.email,
        password: user.password
      }, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      })
      .then(response => {
        if (response.data.access.token) {
          sessionStorage.setItem('user', JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  confirm(user) {
    return axios
      .post(API_URL + '/press/activate', {
        email: user.email,
        hash: user.hash,
      }, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      })
      .then(response => {
        return response.data;
      });
  }

  link(user) {
    return axios
      .post(API_URL + '/auth/forgot/link', {
        email: user.email,
      }, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      })
      .then(response => {
        return response.data;
      });
  }

  recovery(user) {
    return axios
      .post(API_URL + '/auth/forgot', {
        email: user.email,
        hash: user.hash,
        password: user.password,
        password_confirmation: user.password_confirmation,
      }, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      })
      .then(response => {
        return response.data;
      });
  }

  reset(user) {
    return axios
      .post(API_URL + '/press/activate/resend', {
        email: user.email,
      }, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      })
      .then(response => {
        return response.data;
      });
  }


  logout() {
    localStorage.removeItem('user');
  }

  register(user) {
    return axios.post(API_URL + '/auth/register', {
      ...user,

    }, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  proposal(user) {
    return axios.post(API_URL + `/request/${user.uuid}`, {
      count_of_person: user.count_of_person,
      email: user.email,
      phone: user.phone,
    }, {
      headers: {
        ...authHeader(),
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

export default new AuthService();
