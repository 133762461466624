import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL;

class UserService {
  // eslint-disable-next-line class-methods-use-this
  getUserBoard() {
    return axios.get(`${API_URL}/account`, { headers: authHeader() });
  }
}

export default new UserService();
