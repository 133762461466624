<template>
  <div
    :class="$style.ConfirmEmailPage"
  >
    <template v-if="success">
      <div>
        <IconSuccessful/>
      </div>
      <h1 :class="$style.title">
        Вы успешно подтвердили электронный адрес
      </h1>
      <p :class="$style.description">
        <!--    eslint-disable-next-line    -->
        <span :class="$style.linkWrap">
           <VButton
             color="primary"
             to="/login"
             link="/login"
             responsive
           >
            Войти
          </VButton>
        </span>
      </p>
    </template>
    <template v-else-if="!success">
      <h1 :class="$style.title">
        Что-то пошло не так
      </h1>
      <p :class="$style.description">
        <!--    eslint-disable-next-line    -->
        {{ message }}
      </p>
      <p :class="[$style.description, $style._base]">
        <!--    eslint-disable-next-line    -->
        Отправьте письмо повторно по этой <a href="#" @click.prevent="handleReset(this.getHash)">ссылке</a>
      </p>
    </template>
  </div>

</template>

<script>
import IconSuccessful from '@/assets/icons/IconSuccessful.vue';

export default {
  name: 'ConfirmEmailPage',

  components: {
    IconSuccessful,
  },

  props: {
    userEmail: {
      type: Object,
      default: () => {
      },
    },
  },

  data() {
    return {
      success: null,
      error: false,
      message: '',
      user: {},
    };
  },

  mounted() {
    if (this.getHash) {
      this.handleConfirm(this.getHash);
    }
  },

  computed: {
    getHash() {
      return {
        email: this.$route.query.email,
        hash: this.$route.query.hash,
      };
    },
  },

  methods: {
    handleConfirm(user) {
      this.$store.dispatch('auth/confirm', user)
        .then(
          () => {
            this.success = true;
            this.error = false;
          },
          (error) => {
            this.success = false;
            this.message = error.response.data.message ?? '';
            this.error = true;
          },
        );
    },

    handleReset(user) {
      this.$store.dispatch('auth/reset', user)
        .then(
          () => {
            this.success = true;
            this.error = false;
          },
          (error) => {
            this.message = error.response.data.message ?? '';
            this.success = false;
            this.error = true;
          },
        );
    },
  },
};
</script>

<style lang="scss" module>
.ConfirmEmailPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 200px;
}

.title {
  color: $base-0;
  font-weight: 600;
  font-size: 36px;
  text-align: center;
  line-height: 40px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  @media screen and (max-width: 992px){
    font-size: 24px;
  }
  @media screen and (max-width: 767px){
    font-size: 20px;
  }
}

.description {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: $error;

  &._base {
    color: $base-0;
  }

  a {
    font-weight: 600;
    text-decoration: underline;
  }
}

.linkWrap {
  display: block;
  width: 200px;
  margin-top: 20px;
}
</style>
