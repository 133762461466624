<template>
  <!--eslint-disable-->
  <div
    :class="$style.RegistrationForm"
  >
    <h3 :class="$style.title">
      Регистрация
    </h3>
    <form
      :class="$style.form"
      @submit.prevent="submitForm(user)"
    >
      <div :class="$style.fieldset">
        <div
          :class="[$style.inputWrapper, $style._row, {[$style._error] : Boolean(errors.surname) || Boolean(errors.name)}]">
          <VInput
            v-model="user.surname"
            name="surname"
            label="Фамилия*"
            :disabled="isLoading"
            show-label
            :error-msg="errors.surname"
            :error="Boolean(errors.surname)"
            @input="errors.surname = ''"
          />
          <VInput
            v-model="user.name"
            name="name"
            label="Имя*"
            :disabled="isLoading"
            show-label
            :error-msg="errors.name"
            :error="Boolean(errors.name)"
            @input="errors.name = ''"
          />
        </div>
        <div :class="$style.inputWrapper">
          <VInput
            v-model="user.patronymic"
            name="surname"
            label="Отчество"
            :disabled="isLoading"
            show-label
            :error-msg="errors.patronymic"
            :error="Boolean(errors.patronymic)"
            @input="errors.patronymic = ''"
          />
        </div>
        <div :class="$style.inputWrapper">
          <VInput
            v-model="user.legal_entity_name"
            name="nameLawyer"
            label="Наименование юридического лица*"
            :disabled="isLoading"
            show-label
            :error-msg="errors.legal_entity_name"
            :error="Boolean(errors.legal_entity_name)"
            @input="errors.legal_entity_name = ''"
          />
        </div>
        <div :class="[$style.inputWrapper]">
          <div :class="$style.labelSelect">
            <span>Вид юридического лица*</span>
          </div>
          <Multiselect
              name="legal_entity_type"
              label="label"
              :object="true"
              :class="$style.select"
              v-model="legal_entity_type"
              :options="createOptionsLawyerFace"
              ref="multiselect"
            />
        </div>
        <div :class="[$style.inputWrapper]">
          <VInput
            v-model="user.position"
            label="Должность*"
            :disabled="isLoading"
            show-label
            type="tel"
            :error-msg="errors.position"
            :error="Boolean(errors.position)"
            @input="errors.position = ''"
          />
        </div>
        <div :class="[$style.inputWrapper]">
          <VInput
            v-model="user.phone"
            name="phone"
            mask="phone"
            label="Номер телефона*"
            :disabled="isLoading"
            show-label
            type="tel"
            :error-msg="errors.phone"
            :error="Boolean(errors.phone)"
            @input="errors.phone = ''"
          />
        </div>
        <div :class="$style.inputWrapper">
          <VInput
            v-model="user.email"
            name="email"
            label="E-mail*"
            :disabled="isLoading"
            show-label
            :error-msg="errors.email"
            :error="Boolean(errors.email)"
            @input="errors.email = ''"
          />
          <p :class="{[$style._error] : Boolean(errors.email)}">На данную почту будут приходить уведомления о
            результатах рассмотрения заявок</p>
        </div>
        <div
          :class="[$style.inputWrapper, $style._row, {[$style._error] : Boolean(errors.password) || Boolean(errors.password_confirmation)}]">
          <VInput
            v-model="user.password"
            name="password"
            :type="showPass"
            label="Пароль*"
            icon
            :disabled="isLoading"
            show-label
            :error-msg="errors.password"
            :error="Boolean(errors.password)"
            @input="errors.password = ''"
            @showPass="showHidePass"
          />
          <VInput
            v-model="user.password_confirmation"
            name="password_confirmation"
            label="Повторите пароль*"
            :type="showPass"
            icon
            :disabled="isLoading"
            show-label
            :error-msg="errors.password_confirmation"
            :error="Boolean(errors.password_confirmation)"
            @input="errors.password_confirmation = ''"
            @showPass="showHidePass"
          />
        </div>
      </div>
      <div :class="$style.fieldset">
        <div :class="$style.inputWrapper">
          <VFileLoader
            :class="$style.input"
            v-model="user.document"
            @change="getFile"
            name="photo"
            label="Документ"
            :file-formats="['png', 'jpeg', 'pdf']"
            @input=" errors.document = ''"
            :error-msg="errors.document"
            :error="Boolean(errors.document)"
          />
          <p :class="{[$style._error] : Boolean(errors.email)}">
            Добавьте заявку в свободной форме на официальном бланке организации с запросом на сотрудничество
          </p>
          <div :class="$style._mt18">
            <VCheckbox :value="user.has_informational_messages" v-model="informational_messages"
                       name="has_informational_messages" @input="informationMessage">
              Хочу получать информационные сообщения и рассылки от ФК «Динамо Москва»
            </VCheckbox>
          </div>
          <div :class="$style._mt18">
            <VCheckbox :value="user.has_privacy_policy"
                       v-model="policy"
                       name="has_privacy_policy"
                       @change="checkPolicy"
                       :error-msg="errors.has_privacy_policy"
                       :error="Boolean(errors.has_privacy_policy)"
            >
              Согласие с условиями <a style="text-decoration: underline" target="_blank" href="https://fcdm.ru/club/policy">«Политики
              конфиденциальности»</a>
            </VCheckbox>
          </div>
          <VButton
            color="primary"
            type="submit"
            responsive
            :disabled="isLoading"
          >
            Зарегистрироваться
          </VButton>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
// eslint-disable-next-line
import Multiselect from '@vueform/multiselect';
import axios from 'axios';

// const regexName = /^[-\sa-zA-Zа-яА-ЯЁё]+$/;
// const regexPhone = /^.{12}$/;
const regexPass = /(?=.{6,})/;
const regexEmail = /(?:^|\s)[\w!#$%&'*+/=?^`{|}~-](\.?[\w!#$%&'*+/=?^`{|}~-]+)*@\w+[.-]?\w*\.[a-zA-Z]{2,3}\b/;

export default {
  name: 'RegistrationForm',

  components: {
    Multiselect,
  },

  data() {
    return {
      showPass: 'password',
      informational_messages: false,
      policy: false,
      value: null,
      message: '',
      lawyerFace: [],
      representatives: [],
      isFormEnable: null,
      legal_entity_type: {},
      user: {
        name: '',
        surname: '',
        patronymic: '',
        phone: '',
        email: '',
        password: '',
        position: '',
        password_confirmation: '',
        document: '',
        legal_entity_name: '',
        has_informational_messages: '0',
        has_privacy_policy: false,
      },
      isLoading: false,
      requiredFields: {},

      errors: {
        name: '',
        surname: '',
        patronymic: '',
        phone: '',
        email: '',
        password: '',
        password_confirmation: '',
        document: '',
        legal_entity_name: '',
        legal_entity_type: '',
        position: '',
        has_informational_messages: '',
        has_privacy_policy: '',
      },

    };
  },

  computed: {
    createOptionsLawyerFace() {
      return this.lawyerFace.map((el) => ({
        ...el,
        id: el.id,
        value: el.name,
        label: el.name,
      })).sort((a, b) => a.label.localeCompare(b.label));
    },
    createOptionsRepresentatives() {
      return this.representatives.map((el) => ({
        ...el,
        id: el.id,
        value: el.name,
        label: el.name,
      })).sort((a, b) => a.label.localeCompare(b.label));
    },
  },
  created() {
    this.asyncData();
  },

  methods: {
    showHidePass(value) {
      if (value === 'password') {
        this.showPass = 'text';
      } else {
        this.showPass = 'password';
      }
    },

    getFile(value) {
      this.user.document = value;
    },

    async asyncData() {
      const lawyerFace = await axios.get(`${process.env.VUE_APP_API_URL}/references/legal-entity-types`);
      const representatives = await axios.get(`${process.env.VUE_APP_API_URL}/references/representatives`);
      this.lawyerFace = [...lawyerFace.data.data];
      this.representatives = [...representatives.data.data];
    },
    informationMessage() {
      this.informational_messages = !this.informational_messages;

      if (this.user.has_informational_messages === '0') {
        this.user.has_informational_messages = '1';
      } else {
        this.user.has_informational_messages = '0';
      }
    },

    submitForm(user) {
      this.checkEmail();
      // this.checkPhone();
      this.checkPassword();
      this.checkForm();

      if (this.isFormEnable) {
        this.handleRegister(user);
      }
    },

    checkForm() {
      // eslint-disable-next-line no-unused-expressions
      // eslint-disable-next-line
      Object.values(this.errors).every((x) => !x) ? this.isFormEnable = true : this.isFormEnable = false;
    },

    checkPolicy() {
      this.policy = !this.policy;
      this.user.has_privacy_policy = this.policy;
      if (this.errors.has_privacy_policy) {
        this.errors.has_privacy_policy = '';
      }
    },
    handleRegister(user) {
      this.isLoading = true;
      const data = {
        legal_entity_type: this.legal_entity_type.id,
      };

      this.$store.dispatch('auth/register', { ...user, ...data })
        .then(
          () => {
            this.$router.push('/confirm');
          },
          (error) => {
            this.isLoading = false;
            // eslint-disable-next-line
            if (error.response.data.errors) {
              this.errors = error.response.data.errors ?? {};
              Object.entries(error.response.data.errors).forEach(([key, value]) => {
                // eslint-disable-next-line prefer-destructuring
                this.errors[key] = value[0];
              });
            }
            this.message = error.response.data.message ?? '';
          },
        );
    },

    validValue(value, regex) {
      return regex.test(value);
    },

    checkTextField(field) {
      this.errors[field] = '';
      if (!this.requiredFields[field]) {
        this.errors[field] = 'Это поле является обязательным';
      } else if (!this.requiredFields[field].trim().length) {
        this.errors[field] = 'Поле не может быть пустым';
      }
    },

    // checkPhone() {
    //   this.errors.phone = '';
    //   if (!this.user.phone) {
    //     this.errors.phone = 'Это поле является обязательным';
    //   } else if (!this.validValue(this.user.phone.replace(/[()\s-]/g, ''), regexPhone)) {
    //     this.errors.phone = 'Телефон состоит из 11 цифр';
    //   }
    // },

    checkPassword() {
      this.errors.password = '';
      if (!this.user.password) {
        this.errors.password = 'Это поле является обязательным';
      } else if (!this.validValue(this.user.password, regexPass)) {
        this.errors.password = 'Пароль должен состоять из 6 символов и более';
      } else if (this.user.password !== this.user.password_confirmation) {
        this.errors.password = 'Пароли не совпадают';
      }
    },

    checkEmail() {
      this.errors.email = '';
      if (!this.user.email) {
        this.errors.email = 'Это поле является обязательным';
      } else if (!this.validValue(this.user.email, regexEmail)) {
        this.errors.email = 'Заполните поле корректно';
      }
    },
  },
};
</script>
<style lang="scss" module>
.RegistrationForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;

  .form {
    width: 100%;
    max-width: 380px;

    @media screen and (max-width: 767px) {
      max-width: 100%;
      display: block;
    }
  }
}

.title {
  color: $base-0;
  font-weight: 600;
  font-size: 36px;
  text-transform: uppercase;
  font-feature-settings: 'case';

  @media screen and (max-width: 767px) {
    font-size: 24px;
  }
}

.fieldset {
  margin-top: 40px;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.inputWrapper {
  width: 100%;
  max-width: 385px;
  margin: 0 auto 35px;

  &._error {
    margin-bottom: 70px;
  }

  p {
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    color: $base-0;
    margin-top: 8px;

    &._error {
      margin-top: 35px;
    }
  }

  .labelSelect {
    position: relative;

    span {
      z-index: 20;
      font-size: 12px;
      position: absolute;
      top: -8px;
      left: 14px;
      background-color: $white;
      padding: 0 2px;
      font-weight: 400;
      pointer-events: none;
      transform-origin: left center;
      transition: transform .3s ease;
      color: $base-0;
    }
  }

  &._row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    :global(.v-input) {
      width: calc(50% - 8px);
    }
    .labelSelect {
      width: calc(50% - 8px);
    }
  }

  ._mt18 {
    margin-top: 18px;
    margin-bottom: 20px;
  }

  ._mt10 {
    margin-top: 10px;
  }

  .select {
    min-height: 50px;
    border-color: $base-0;

    &:global(.is-active) {
      box-shadow: none;
    }

    :global(.multiselect-option.is-selected) {
      background: $base-0;
    }
  }
}
</style>
