<template>
  <!--eslint-disable-->
  <div
    :class="$style.ProposalForm"
  >
    <template v-if="!isRequest">
      <h3 :class="$style.title">
        Форма подачи заявки
      </h3>
      <form
        :class="$style.form"
        @submit.prevent="submitForm(user)"
      >
        <div :class="$style.fieldset">
          <div
            :class="[$style.inputWrapper, $style._row, {[$style._error] : Boolean(errors.surname) || Boolean(errors.name)}]">
            <VInput
              v-model="user.surname"
              :value="userLoad.surname"
              preset-value
              name="surname"
              label="Фамилия"
              disabled
              show-label
            />
            <VInput
              v-model="user.name"
              :value="userLoad.name"
              preset-value
              name="name"
              label="Имя"
              disabled
              show-label
            />
          </div>
          <div :class="$style.inputWrapper">
            <VInput
              v-model="user.patronymic"
              :value="userLoad.patronymic"
              name="surname"
              preset-value
              label="Отчество"
              disabled
              show-label
            />
          </div>
          <div :class="[$style.inputWrapper]">
            <VInput
              v-model="user.legal_entity.name"
              :value="userLoad.legal_entity.name"
              preset-value
              name="nameLaweyr"
              label="Наименование юридического лица*"
              disabled
              show-label
            />
          </div>
        </div>
        <div :class="$style.inputWrapper">
          <VInput
            v-model="user.legal_entity.type"
            :value="userLoad.legal_entity.type"
            preset-value
            label="Вид юридического лица*"
            disabled
            show-label
          />
        </div>
        <div :class="$style.inputWrapper">
          <VInput
            v-model="user.email"
            label="E-mail*"
            show-label
            name="email"
            :disabled="isLoading"
            :error-msg="errors.email"
            :error="Boolean(errors.email)"
            @input="errors.email = ''"
          />
          <p :class="{[$style._error] : Boolean(errors.email)}">
            На указанный e-mail будет отправлен ответ по заявке
          </p>
        </div>
        <div :class="$style.inputWrapper">
          <VInput
            v-model="user.phone"
            label="Контактный номер телефона*"
            show-label
            name="phone"
            mask="phone"
            type="tel"
            :disabled="isLoading"
            :error-msg="errors.phone"
            :error="Boolean(errors.phone)"
            @input="errors.phone = ''"
          />
        </div>
        <div :class="$style.inputWrapper">
          <VInput
            v-model="user.count_of_person"
            label="Количество человек*"
            show-label
            type="number"
            :disabled="isLoading"
            :error-msg="errors.count_of_person"
            :error="Boolean(errors.count_of_person)"
            @input="errors.count_of_person = ''"
          />
        </div>
        <VButton
          color="primary"
          type="submit"
          responsive
          :disabled="isLoading"
        >
          отправить
        </VButton>
      </form>
    </template>
    <template v-if="isRequest">
      <div :class="$style.message">
        спасибо! <br>
        ваша заявка отправлена
      </div>
      <VButton
        :class="$style.btn"
        color="primary"
        responsive
        @click="$emit('close')"
      >
        Понятно
      </VButton>
    </template>

  </div>
</template>

<script>
const regexPhone = /^.{12}$/;
const regexEmail = /(?:^|\s)[\w!#$%&'*+/=?^`{|}~-](\.?[\w!#$%&'*+/=?^`{|}~-]+)*@\w+[.-]?\w*\.[a-zA-Z]{2,3}\b/;

export default {
  name: 'ProposalForm',

  components: {},

  props: {
    userLoad: {
      type: Object,
      default: () => {
      },
    },
    uuid: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      message: '',
      isRequest: false,
      options: [],
      isFormEnable: null,
      isLoading: false,
      user: {
        name: '',
        surname: '',
        patronymic: '',
        legal_entity: {
          name: '',
          type: '',
        },
        email: '',
        phone: '',
        count_of_person: '',
      },

      errors: {
        email: '',
        phone: '',
        count_of_person: '',
      },

    };
  },

  methods: {
    submitForm(user) {
      this.checkEmail();
      this.checkPhone();
      this.checkForm();
      if (this.isFormEnable) {
        this.handleProposal(user);
      }
    },

    checkForm() {
      // eslint-disable-next-line no-unused-expressions
      // eslint-disable-next-line
      Object.values(this.errors).every((x) => !x) ? this.isFormEnable = true : this.isFormEnable = false;
    },
    handleProposal(user) {
      this.isLoading = true;
      this.user.uuid = this.uuid;
      this.$store.dispatch('auth/proposal', { ...user })
        .then(
          (response) => {
            this.isRequest = true;
            this.$emit('isRequest', this.isRequest);
            this.message = response.message ?? '';
          },
          (error) => {
            this.isLoading = false;
            // eslint-disable-next-line
            if (error.response.data.errors) {
              this.errors = error.response.data.errors ?? {};
              Object.entries(error.response.data.errors).forEach(([key, value]) => {
                // eslint-disable-next-line prefer-destructuring
                this.errors[key] = value[0];
              });
            }
            this.message = error.response.data.message ?? '';
          },
        );
    },

    validValue(value, regex) {
      return regex.test(value);
    },

    checkPhone() {
      this.errors.phone = '';
      if (!this.user.phone) {
        this.errors.phone = 'Это поле является обязательным';
      } else if (!this.validValue(this.user.phone.replace(/[()\s-]/g, ''), regexPhone)) {
        this.errors.phone = 'Телефон состоит из 11 цифр';
      }
    },

    checkEmail() {
      this.errors.email = '';
      if (!this.user.email) {
        this.errors.email = 'Это поле является обязательным';
      } else if (!this.validValue(this.user.email, regexEmail)) {
        this.errors.email = 'Заполните поле корректно';
      }
    },
  },
};
</script>
<style lang="scss" module>
.ProposalForm {
  width: 100%;

  .form {
    width: 100%;

    @media screen and (max-width: 767px) {
      max-width: 100%;
      display: block;
    }
  }
}

.title {
  color: $base-0;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 40px;
  text-align: center;
  text-transform: uppercase;
  font-feature-settings: 'case';

  @media screen and (max-width: 767px) {
    font-size: 18px;
  }
}

.inputWrapper {
  width: 100%;
  max-width: 365px;
  margin: 0 auto 35px;

  &._error {
    margin-bottom: 70px;
  }

  p {
    margin-top: 10px;
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    color: $base-0;

    &._error {
      margin-top: 35px;
      color: $error;
    }
  }

  .titleRadio {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 15px;
  }

  .labelSelect {
    position: relative;

    span {
      z-index: 20;
      font-size: 14px;
      position: absolute;
      bottom: 50%;
      left: 14px;
      background-color: $white;
      padding: 0 2px;
      font-weight: 400;
      pointer-events: none;
      transform-origin: left center;
      transition: transform .3s ease;
      color: $base-0;
      transform: translateY(10px) scale(.7);
    }
  }

  &._row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    :global(.v-input) {
      width: calc(50% - 8px);
    }
  }

  ._mt18 {
    margin-top: 18px;
  }

  ._mt10 {
    margin-top: 10px;
  }

  .select {
    min-height: 50px;
    border-color: $base-0;

    &:global(.is-disabled) {
      border-color: rgba($base-0, .1);
      background-color: $white;
      color: rgba($base-0, .2);
    }
  }
}

.wrap {
  border: 1px solid $base-0;
  border-radius: 5px;
  padding: 15px 20px;
  margin-top: 12px;

  a {
    text-decoration: underline;
    font-weight: 600;
  }
}

.radioBtn {
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid $base-0;
  font-weight: 400;
  font-size: 14px;
  padding: 8px 14px;

  &:nth-child(2) {
    margin-right: 10px;
  }

  &._active {
    background-color: $base-0;
    color: $white;
  }
}

.message {
  font-weight: 600;
  font-size: 21px;
  text-align: center;
  line-height: 30px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.btn {
  margin-top: 30px;
}
</style>
