import { createApp } from 'vue';
import components from '@/components/ui';
import router from '@/router/router';
import store from '@/store';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from '@sentry/vue';
import App from './App.vue';

const app = createApp(App);

components.forEach((component) => {
  app.component(component.name, component);
});

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DNS,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

app
  .use(router)
  .use(store)
  .mount('#app');
