<template>
  <div
    :class="$style.loginForm"
  >
    <h3 :class="$style.title">
      Войти в аккаунт
    </h3>
    <form @submit.prevent="submitForm(user)">
      <div :class="$style.fieldset">
        <div :class="$style.inputWrapper">
          <VInput
            v-model="user.email"
            name="email"
            label="E-mail*"
            :disabled="isLoading"
            show-label
            :error-msg="errors.email ? errors.email : message"
            :error="Boolean(errors.email)"
            @input="errors.email = ''"
          />
          <div :class="$style.linkResetEmail">
            <a href="#"
               v-if="message === 'Учетная запись не подтверждена'"
               @click.prevent="handleReset(user)"
            >
              Отравить письмо повторно
            </a>
          </div>
        </div>
        <div :class="[$style.inputWrapper]">
          <div :class="$style.linkPass">
            <router-link to="/forgot/email">Забыли пароль?</router-link>
          </div>
          <VInput
            v-model="user.password"
            name="password"
            type="password"
            label="Пароль*"
            :disabled="isLoading"
            show-label
            :error-msg="errors.password"
            :error="Boolean(errors.password)"
            @input="errors.password = ''"
          />
          <div :class="$style.checkbox">
            <VCheckbox v-model="remember"
                       name="remember" @change="rememberMe">
              Запомнить меня
            </VCheckbox>
          </div>
        </div>
        <VButton
          color="primary"
          type="submit"
          responsive
          :disabled="isLoading"
        >
          Войти
        </VButton>
      </div>
    </form>
    <p :class="$style.linkReg">У вас нет аккаунта?
      <router-link to="/registration">Создать</router-link>
    </p>
  </div>
</template>

<script>
const regexEmail = /(?:^|\s)[\w!#$%&'*+/=?^`{|}~-](\.?[\w!#$%&'*+/=?^`{|}~-]+)*@\w+[.-]?\w*\.[a-zA-Z]{2,3}\b/;
export default {
  name: 'LoginForm',

  data() {
    return {
      remember: false,
      isFormEnable: null,
      message: '',
      user: {
        email: '',
        password: '',
      },
      isLoading: false,
      errors: {
        email: '',
        password: '',
      },
    };
  },

  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/matches');
    }
  },
  methods: {
    rememberMe() {
      this.remember = !this.remember;
    },

    checkEmail() {
      this.errors.email = '';
      if (!this.user.email) {
        this.errors.email = 'Это поле является обязательным';
      } else if (!this.validValue(this.user.email, regexEmail)) {
        this.errors.email = 'Заполните поле корректно';
      }
    },

    submitForm(user) {
      this.checkEmail();
      this.checkForm();

      if (this.isFormEnable) {
        this.handleLogin(user);
      }
    },

    checkForm() {
      // eslint-disable-next-line no-unused-expressions
      // eslint-disable-next-line
      Object.values(this.errors).every((x) => !x) ? this.isFormEnable = true : this.isFormEnable = false;
    },

    handleReset(user) {
      this.$store.dispatch('auth/reset', user)
        .then(
          () => {
            this.message = '';
          },
          (error) => {
            this.message = error.response.data.message ?? '';
          },
        );
    },

    validValue(value, regex) {
      return regex.test(value);
    },

    handleLogin(user) {
      this.isLoading = true;

      this.$store.dispatch('auth/login', user)
        .then(
          () => {
            this.$router.push('/matches');
          },
          (error) => {
            this.isLoading = false;
            this.isFormEnable = false;
            // eslint-disable-next-line
            if (error.response.data.errors) {
              this.errors = error.response.data.errors ?? {};
              Object.entries(error.response.data.errors).forEach(([key, value]) => {
                // eslint-disable-next-line prefer-destructuring
                this.errors[key] = value[0];
              });
            }
            this.message = error.response.data.message ?? '';
            // if (error.response.data.message === 'Учетная запись не подтверждена') {
            //   this.message = `Учетная запись не подтверждена. <a>`
            // }
          },
        );
    },
  },
};
</script>

<style lang="scss" module>
.loginForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;

  form {
    width: 100%;
    max-width: 365px;
    @media screen and (max-width: 767px) {
      max-width: 100%;
      display: block;
    }
  }
}

.title {
  color: $base-0;
  font-weight: 600;
  font-size: 36px;
  text-transform: uppercase;
  font-feature-settings: 'case';
  @media screen and (max-width: 767px) {
    font-size: 24px;
  }
}

.inputWrapper {
  position: relative;
  width: 100%;
  max-width: 365px;
  margin-bottom: 35px;

  ._mt18 {
    margin-top: 18px;
  }

  ._mt10 {
    margin-top: 10px;
  }
}

.fieldset {
  margin-top: 40px;
}

.linkReg {
  margin-top: 20px;
  font-weight: 400;
  font-size: 14px;

  a {
    text-decoration: underline;
  }
}

.linkPass {
  text-align: right;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  color: $base-0;
}

.linkResetEmail {
  margin-top: 20px;
  font-weight: 400;
  font-size: 14px;
  color: $base-0;
  text-decoration: underline;
}

.checkbox {
  margin-top: 20px;
}
</style>
