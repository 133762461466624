<template>
  <!-- eslint-disable -->
  <component :is="component"
             v-bind="linkProperties"
             class="v-button"
             :class="classes"
             :disabled="disabled"
             :type="!link && !href ? type : null"
  >

        <span v-if="$slots.default"
              class="v-button__text"
              :class="{'_icon': hasIcon}"
        >
            <slot></slot>
        </span>
  </component>
</template>

<script>
/* eslint-disable */
export default {
  name: 'VButton',

  props: {
    /**
     * Определяет классы, которые будут модифицировать размер
     */
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        return ['large', 'medium', 'small'].indexOf(value) !== -1;
      },
    },

    /**
     * Определяет классы, которые будут модифицировать цвет
     */
    color: {
      type: String,
      default: 'primary',
    },

    /**
     * Устанавливает класс для определения обратного цвета
     */
    inversed: {
      type: Boolean,
      default: false,
    },

    /**
     * Очевидно, что это свойство задизейблет кнопку
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Растягивает кнопку на всю ширину внешнего контейнера
     */
    responsive: {
      type: Boolean,
      default: false,
    },

    /**
     * Меняет тип кнопки
     */
    type: {
      type: String,
      default: 'button',
    },

    /**
     * Устанавливает тег a для кнопки
     */
    href: {
      type: String,
      default: '',
    },

    /**
     * target="_blank" для ссылок
     */
    blank: {
      type: Boolean,
      default: false,
    },

    /**
     * Для внутренних ссылок (тег n-link)
     */
    link: {
      type: [String, Object],
      default: '',
    },

    disabledHover: {
      type: Boolean,
      default: false,
    },

    isAccess: {
      type: Boolean,
      default: false,
    },

    inProgress: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    hasIcon: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    component() {
      if (this.href) {
        return 'a';
      } else if (this.link) {
        return 'router-link';
      }
      return 'button';
    },

    linkProperties() {
      const linkProperties = {};
      if (this.link) {
        linkProperties.to = this.link;
      } else if (this.href) {
        linkProperties.href = this.href;
      }

      if (this.blank) {
        linkProperties.target = '_blank';
      }
      return linkProperties;
    },

    classes() {
      return [
        `v-button--${this.color}`,
        `v-button--${this.size}`,
        {
          'is-inversed': this.inversed,
          'is-disabled': this.disabled,
          'is-responsive': this.responsive,
          'is-disabled-hover': this.disabledHover,
          'is-loading': this.loading,
          'is-access': this.isAccess,
          'is-progress': this.inProgress,
        },
      ];
    },
  },

  methods: {
    onClick(e) {
      if (this.disabled || this.loading) {
        return;
      }
      this.$emit('click', e);
    },
  },
};
</script>

<style lang="scss">
.v-button {
  $block: &;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  outline: none;
  text-align: center;
  white-space: nowrap;
  font-weight: 500;
  line-height: 1;
  transition: $default-transition;
  cursor: pointer;
  user-select: none;

  /* Colors */

  &--primary {
    &:not(.is-inversed) {
      border-color: $base-10;
      background-color: $base-10;
      color: $base-0;
      fill: $base-0;

      @include hover {
        border-color: $base-0;
         color: $white;
        background-color: $base-0;
      }

      &:active {
        background-color: $base-0;
        color: $base-0;
        fill: $base-0;
      }

      &.is-disabled {
        border-color: #8FA2B3;
        background-color: $white;
        color: #8FA2B3;
        pointer-events: none;
      }
    }


    &.is-inversed {
      border-color: $base-0;
      background-color: $white;
      color: $base-0;
      fill: $base-0;

      @include hover {
        border-color: $base-0;
        background-color: $base-0;
        color: $white;
      }

      &:active {
        background-color: $base-0;
        color: $base-0;
        fill: $base-0;
      }

      &.is-disabled {
        border-color: #EAEAEA;
        background-color: #EAEAEA;
        color: $base-110;
        pointer-events: none;
      }

      &.is-access {
        border-color: $access;
        background-color: $access;
        color: $white;
      }

      &.is-progress {
        border-color: $base-0;
        background-color: $base-0;
        color: $white;
        pointer-events: none;
      }
    }
  }

  &--secondary {
    &:not(.is-inversed) {
      border-color: rgba($base-0, .24);
      color: $base-0;
      fill: $base-0;

      @include hover {
        background-color: rgba($base-0, .24);
      }

      &:active {
        border-color: $base-0;
      }

      &.is-disabled {
        color: rgba($base-0, .24);
        pointer-events: none;
      }
    }

    &.is-inversed {
      border-color: $base-0;
      background-color: transparent;
      color: $base-0;
      fill: $base-0;

      @include hover {
        border-color: $base-0;
        background-color: $base-0;
      }

      &:active {
        border-color: $base-0;
      }

      &.is-disabled {
        color: $base-0;
        pointer-events: none;
      }
    }
  }

  //secondary + inversed на сером фоне:
  &--additional {
    border-color: $base-0;
    background-color: transparent;
    color: $base-0;
    fill: $base-0;

    @include hover {
      border-color: $base-0;
      background-color: $base-0;
    }

    &:active {
      border-color: $base-0;
      background-color: $base-0;
    }

    &.is-inversed {
      @include hover {
        border-color: $base-0;
        background-color: $base-0;
      }
    }

    &.is-disabled {
      color: $base-0;
      pointer-events: none;
    }
  }

  &--error {
    border-color: $error;
    background-color: $base-0;
    color: $error;
    fill: $error;

    @include hover {
      background-color: $error;
      color: $base-0;
      fill: $base-0;
    }

    &:active {
      border-color: $base-0;
      background-color: $base-0;
      color: $base-0;
      fill: $base-0;
    }

    &.is-disabled {
      border-color: $base-0;
      color: $base-0;
      fill: $base-0;
      pointer-events: none;
    }
  }

  /* End colors */

  /* Sizes */

  &--large {
    height: 5.6rem;
    padding: 0 3.2rem;
    border-radius: 2.8rem;
    font-size: 1.6rem;

    &.is-responsive {
      padding: 0;
    }
  }

  &--medium {
    height: 40px;
    padding: 0 24px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.29;
    letter-spacing: 1px;
    text-transform: uppercase;

    &.is-responsive {
      padding: 0;
    }
  }

  &--small {
    height: 3.6rem;
    padding: 0 1.6rem;
    border-radius: 2.4rem;
    font-size: 1.4rem;
    line-height: 1.29;

    &.is-responsive {
      padding: 0;
    }
  }

  /* End Sizes */

  /* Modificators */

  &.is-loading {
    pointer-events: none;

    &:after {
      opacity: 1;
    }

    #{$block}__text {
      opacity: 0;
    }
  }

  &.is-responsive {
    width: 100%;
  }

  /* End Modificators */

  &__text {
    display: flex;
    align-items: center;
    opacity: 1;
    transition: opacity $default-transition;

    &._icon {
      & svg {
        margin-right: 1rem;
      }
    }
  }
}
</style>
