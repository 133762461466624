<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <button @click="$emit('close')" class="close">
          <IconClose/>
        </button>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import IconClose from '@/assets/icons/IconClose.vue';

export default {
  name: 'VModal',
  components: {
    IconClose,
  },
};
</script>

<style lang="scss">
.modal-mask {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
    overflow-y: auto;
}

.modal-wrapper {
  margin: 0 auto;
  display: block;
  vertical-align: middle;
}

.modal-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  width: 450px;
  min-height: 200px;
  margin: 0px auto;
  padding: 40px 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  border: 2px solid $base-0;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.modal-header {
  .title {
    color: $base-0;
    font-weight: 600;
    font-size: 24px;
    text-transform: uppercase;
    font-feature-settings: 'case';

    @media screen and (max-width: 767px) {
      font-size: 24px;
    }
  }
}
.close {
  position: absolute;
  top: 20px;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from, .modal-leave-to {
  opacity: 0;
}

.modal-enter-active .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>
