<template>
  <div
    :class="[$style.match, {[$style._active]: active}]"
  >
    <div :class="$style.block">
      <p :class="$style.date">
        {{ match.date }}
      </p>
      <span :class="$style.stadium">
        {{ match.stadium }}
      </span>
    </div>
    <div :class="$style.block">
      <div :class="$style.wrap">
        <span :class="[$style.teamName, $style._right]">
          {{ match.teams.owner.name }}
        </span>
        <div :class="$style.imgWrapper">
          <img :src="match.teams.owner.logo" alt="">
        </div>
      </div>
      <div :class="$style.line"/>
      <div :class="[$style.wrap, $style._reverse]">
        <span :class="[$style.teamName, $style._left]">
          {{ match.teams.guest.name }}
        </span>
        <div :class="$style.imgWrapper">
          <img :src="match.teams.guest.logo" alt="">
        </div>
      </div>
    </div>
    <div :class="$style.block">
      <div :class="$style.imgWrapper" v-if="match.tournament.logo">
        <img :src="match.tournament.logo" alt="">
      </div>
      <div :class="$style.text">
        <span :class="$style.stage">
          {{ match.tournament.name }}
      </span>
        <span :class="$style.stage">
          {{ match.tournament.stage }}
      </span>
      </div>
    </div>
    <div :class="$style.block">
      <VButton
        responsive
        inversed
        :inProgress="match.status.slug === 'in_process'"
        :isAccess="match.status.slug === 'approve'"
        color="primary"
        :disabled="match.has_request && match.status.slug !== 'approve'
        || !match.has_request && match.status.slug === 'expired'
        || match.has_request && match.status.slug !== 'closed' && match.status.slug !== 'approve'"
        @click="handleEventClick()"
      >
        <template v-if="!match.has_request && match.status.slug !== 'expired'">
          подать заявку
        </template>
        <template v-else>
          {{ match.status.name }}
          <template v-if="match.status.slug === 'approve'">
            <IconArrowDown/>
          </template>
        </template>
      </VButton>
      <div
        v-if="active"
        :class="[$style.promocode, {[$style._active]: active}]"
      >
        <span>Промокод</span>
        <div :class="$style.wrap_promocode">
          {{ match.info.promocode }}
        </div>
        <button
          :class="$style.link_rules"
          @click.prevent="openModal"
        >
          правила
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import IconArrowDown from '@/assets/icons/IconArrowDown.vue';

export default {
  name: 'VMatch',
  components: {
    IconArrowDown,
  },

  props: {
    match: {
      type: Object,
      default: () => {
      },
    },
    hasRequest: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showModal: false,
      active: false,
    };
  },

  methods: {
    openModal() {
      this.showModal = true;
      this.$emit('openModal', {
        show: this.showModal,
        rules: this.match.info.rules,
      });
    },

    handleEventClick() {
      if (this.match.status.slug === 'open') {
        this.openModal();
        // eslint-disable-next-line
      } else if (this.match.status.slug === 'approve') {
        this.active = !this.active;
      } else {
        // eslint-disable-next-line
        return;
      }
    },
  },
};
</script>

<style lang="scss" module>
.match {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 90px;
  padding: 15px;
  background-color: $white;
  border-bottom: 1px solid #DEE4EA;
  overflow: hidden;
  transition: height .2s ease-in;

  &._active {
    height: 175px;
    @media screen and (max-width: 767px) {
      height: 250px;
      transition: height .2s ease-in;
      align-items: flex-start;
    }
  }

  @media screen and (max-width: 1189px) {
    flex-wrap: wrap;
    height: auto;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.promocode {
  margin-top: 10px;
  width: 100%;
  transform: translateY(100%);
  transition-delay: 5s;
  transition: translate .2s ease-in;

  &._active {
    transform: translateY(0);
    transition-delay: 5s;
    transition: translate .2s ease-in;
  }

  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: $base-0;
  }

  .wrap_promocode {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0 10px;
    height: 35px;
    width: 100%;
    border: 1px solid $base-0;
  }

  .link_rules {
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: $base-0;
  }
}

.block {
  display: flex;
  align-items: center;

  svg {
    margin-left: 10px;
  }

  @media screen and (max-width: 1189px) {
    :global(.v-button) {
      font-size: 8px;
    }
  }

  &:first-child {
    width: 15%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    @media screen and (max-width: 1189px) {
      width: 50%;
    }
  }

  &:nth-child(2) {
    width: 35%;
    @media screen and (max-width: 1189px) {
      width: 50%;
    }
  }

  &:nth-child(3) {
    width: 30%;
    @media screen and (max-width: 1189px) {
      width: 50%;
      margin-top: 25px;
    }
  }

  &:last-child {
    width: 20%;
    flex-wrap: wrap;
    @media screen and (max-width: 1189px) {
      width: 50%;
      margin-top: 25px;
    }
  }
}

.date {
  font-weight: 500;
  font-size: 26px;
  color: $base-0;
  @media screen and (max-width: 1189px) {
    font-size: 18px;
  }
}

.stadium {
  font-weight: 400;
  font-size: 14px;
  color: $base-0;
  @media screen and (max-width: 1189px) {
    font-size: 12px;
  }
}

.teamName {
  font-weight: 500;
  font-size: 18px;
  color: $base-0;
  max-width: 140px;
  text-transform: uppercase;
  @media screen and (max-width: 1189px) {
    font-size: 12px;
  }

  &._right {
    margin-right: 12px;
    text-align: right;
  }

  &._left {
    margin-left: 12px;
    text-align: left;
  }
}

.line {
  width: 2px;
  height: 60px;
  background-color: $base-10;
  margin: 0 25px;
  @media screen and (max-width: 1189px) {
    margin: 0 10px;
  }
}

.stage {
  font-weight: 500;
  font-size: 16px;
  color: $base-0;
  margin-left: 15px;
  @media screen and (max-width: 1189px) {
    font-size: 12px;
  }
}

.defaultLayout {
  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & > svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .1;
  }
}

.wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50%;

  @media screen and (max-width: 1189px) {
    flex-direction: column-reverse;
  }

  &._reverse {
    flex-direction: row-reverse;
    @media screen and (max-width: 1189px) {
      flex-direction: column-reverse;
    }
  }
}

.imgWrapper {
  position: relative;
  width: 60px;
  height: 60px;
  @media screen and (max-width: 1189px) {
    width: 25px;
    height: 25px;
  }
}

.text {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
</style>
