<template>
    <!-- eslint-disable -->
    <svg viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg" :class="$style.icon">
        <path d="M2.28261 -3.37338e-07L5 4.27586L7.71739 -9.9776e-08L10 0L6.08696 6L3.91304 6L0 -4.37114e-07L2.28261 -3.37338e-07Z"
              fill="white"/>
    </svg>

</template>

<script>
/* eslint-disable */
export default {
name: 'IconArrowDown',
};
</script>

<style lang="scss" module>
.icon {
width: 10px;
height: 6px;
}
</style>

