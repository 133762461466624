<template>
  <div
    :class="$style.footer"
  >
    <div :class="['container', $style.wrapper]">
      <div :class="$style.links">
        <div :class="$style.column">
          <div :class="$style.title">
            КОМАНДЫ
          </div>
          <ul>
            <li v-for="(item, index) in links[0]" :key="index">
              <a :href="item.link"> {{ item.name }}</a>
            </li>
          </ul>
        </div>
        <div :class="$style.column">
          <div :class="$style.title">
            КЛУБ
          </div>
          <ul>
            <li v-for="(item, index) in links[1]" :key="index">
              <a :href="item.link"> {{ item.name }}</a>
            </li>
          </ul>
        </div>
        <div :class="$style.column">
          <div :class="$style.title">
            ВТБ АРЕНА
          </div>
          <ul>
            <li v-for="(item, index) in links[2]" :key="index">
              <a :href="item.link"> {{ item.name }}</a>
            </li>
          </ul>
        </div>
        <div :class="$style.column">
          <div :class="$style.title">
            АКАДЕМИЯ
          </div>
          <ul>
            <li v-for="(item, index) in links[3]" :key="index">
              <a :href="item.link"> {{ item.name }}</a>
            </li>
          </ul>
        </div>
        <div :class="$style.column">
          <div :class="$style.title">
            НОВОСТИ
          </div>
          <ul>
            <li v-for="(item, index) in links[4]" :key="index">
              <a :href="item.link"> {{ item.name }}</a>
            </li>
          </ul>
        </div>
        <div :class="$style.column">
          <div :class="$style.title">
            СОЦИАЛЬНЫЕ СЕТИ
          </div>
          <ul>
            <li v-for="(item, index) in links[5]" :key="index">
              <a :href="item.link"> {{ item.name }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div :class="$style.link_mobile">
        <a v-for="(item, index) in links_mobile"
           :key="index"
           :href="item.link"
        >
          {{ item.name }}
        </a>
      </div>
      <div :class="$style.copyrights">
        © 1923—2023 ФК «Динамо» Москва.<br>
        Все права защищены.
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'VFooter',
  data() {
    return {
      links_mobile: [
        {
          link: 'https://fcdm.ru/teams/dynamo/2022-2023/players ',
          name: 'КОМАНДА',
        },
        {
          link: 'https://fcdm.ru/club/about ',
          name: 'КЛУБ',
        },
        {
          link: 'https://fcdm.ru/news',
          name: 'НОВОСТИ',
        },
        {
          link: 'https://fcdm.ru/club/clubmedia',
          name: 'СОЦИАЛЬНЫЕ СЕТИ',
        },
        {
          link: 'https://fcdm.ru/club/howtoget',
          name: 'ВТБ АРЕНА',
        },
        {
          link: 'https://fcdm.ru/fanid/',
          name: 'КАРТА БОЛЕЛЬЩИКА',
        },
      ],
      links: [
        [
          {
            link: 'https://fcdm.ru/teams/dynamo/2022-2023/players ',
            name: 'Динамо',
          },
          {
            link: 'https://w.fcdm.ru/teams/wfc-dynamo/2023/players',
            name: 'ЖФК',
          },
          {
            link: 'https://d2.fcdm.ru/teams/dynamo2/2022-2023/players',
            name: 'Динамо-2',
          },
          {
            link: 'https://academy.fcdm.ru/teams/players/dynamo',
            name: 'Академия',
          },
        ],
        [
          {
            link: 'https://fcdm.ru/club/about',
            name: 'О Динамо',
          },
          {
            link: 'https://fcdm.ru/club/contacts',
            name: 'Контакты',
          },
          {
            link: 'https://franchise.fcdm.ru/',
            name: 'Франшиза',
          },
          {
            link: 'https://fund.fcdm.ru/',
            name: 'Эндаумент-фонд',
          },
          {
            link: 'https://fcdm.ru/club/shareholders',
            name: 'Акционерам',
          },
        ],
        [
          {
            link: 'https://fcdynamo.ru/club/howtoget',
            name: 'Как добраться',
          },
          {
            link: 'https://fcdynamo.ru/club/parking',
            name: 'Парковки',
          },
          {
            link: 'https://fcdynamo.ru/club/disabledfans',
            name: 'Места для МГН',
          },
          {
            link: 'https://tickets.fcdm.ru/faq',
            name: 'Информация',
          },
          {
            link: 'https://fcdm.ru/fanid/',
            name: 'Карта болельщика',
          },
        ],
        [
          {
            link: 'https://academy.fcdm.ru/academy/',
            name: 'Об Академии',
          },
          {
            link: 'https://fund.fcdm.ru/',
            name: 'Эндаумент-фонд',
          },
          {
            link: 'https://franchise.fcdm.ru/',
            name: 'Франшиза',
          },
        ],
        [
          {
            link: 'https://fcdm.ru/news',
            name: 'Новости',
          },
          {
            link: 'https://fcdm.ru/dynamo_tv',
            name: 'Динамо-ТВ',
          },
          {
            link: 'https://fcdm.ru/news?types=gallery',
            name: 'Фотогалерея',
          },
        ],
        [
          {
            link: 'https://youtube.com/fcdynamo',
            name: 'YouTube',
          },
          {
            link: 'https://vk.com/fcdm_official',
            name: 'ВКонтакте',
          },
          {
            link: 'https://t.me/fcdynamo',
            name: 'Telegram',
          },
          {
            link: 'https://tiktok.com/@fcdynamo',
            name: 'Tiktok',
          },
          {
            link: 'https://ok.ru/fcdynamo',
            name: 'Одноклассники',
          },
          {
            link: 'https://zen.yandex.ru/fcdm',
            name: 'Яндекс.Дзен',
          },
        ],
      ],
    };
  },
};
</script>

<style lang="scss" module>
.footer {
  width: 100%;
  min-height: 325px;
  padding: 40px 0 20px;
  background-color: $base-0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
}

.links {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  @media screen and (max-width: 767px) {
    display: none;
    width: 100%;
    flex-wrap: wrap;
  }

  ul {
    color: $white;
    font-weight: 500;
    font-size: 14px;

    li {
      margin-bottom: 8px;
    }
  }
}

.link_mobile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  @media screen and (min-width: 768px) {
    display: none;
  }

  a {
    font-weight: 600;
    font-size: 17px;
    margin-bottom: 20px;
    color: $white;
  }
}

.column {
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-bottom: 24px;
  }

  &:not(:last-child) {
    margin-right: 50px;
  }
}

.title {
  font-weight: 600;
  font-size: 17px;
  margin-bottom: 20px;
  color: $white;
}

.copyrights {
  font-weight: 400;
  font-size: 14px;
  color: $white;
  margin-top: 20px;
}
</style>
