<template>
  <div
    :class="$style.loginPage"
  >
    <LoginForm/>
  </div>
</template>

<script>
import LoginForm from '@/components/LoginForm.vue';

export default {
  name: 'LoginPage',
  components: {
    LoginForm,
  },
};
</script>

<style lang="scss" module>
.loginPage {

}
</style>
