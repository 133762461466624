// eslint-disable-next-line
import {createRouter, createWebHistory} from 'vue-router';
import LoginPage from '@/pages/LoginPage.vue';
import RegistrationPage from '@/pages/RegistrationPage.vue';
import MatchesPage from '@/pages/MatchesPage.vue';
import ConfirmPage from '@/pages/ConfirmPage.vue';
import ConfirmEmailPage from '@/pages/ConfirmEmailPage.vue';
import ResetPage from '@/pages/ResetPage.vue';
import NotFound from '@/pages/NotFound.vue';

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    component: LoginPage,
  },
  {
    path: '/registration',
    component: RegistrationPage,
  },
  {
    path: '/confirm',
    component: ConfirmPage,
  },
  {
    path: '/confirm/email',
    component: ConfirmEmailPage,
  },
  {
    path: '/matches',
    component: MatchesPage,
  },
  {
    path: '/forgot/email',
    component: ResetPage,
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
  },

];

const router = createRouter({
  routes,
  history: createWebHistory(process.env.BASE_URL),
});

export default router;
