<template>
  <!-- eslint-disable -->
  <label class="wrapper flex items-center" :class="classes">
    <slot></slot>
    <input class="checkbox" type="checkbox" :checked="isChecked" :value="value" @change="updateInput"/>
    <span class="checkmark"></span>
  </label>
  <transition name="fade">
    <div v-if="errorMsg" class="v-checkbox__error">
      <span class="v-checkbox__error-text">{{ errorMsg }}</span>
    </div>
  </transition>
</template>

<script>
/* eslint-disable */
export default {
  name: 'VCheckbox',
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    value: {
      type: String, Boolean,
    },
    modelValue: {
      default: '',
    },
    trueValue: {
      default: true,
    },
    falseValue: {
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },

    errorMsg: {
      type: String,
      default: '',
    },
  },
  computed: {
    isChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value);
      }
      // Note that `true-value` and `false-value` are camelCase in the JS
      return this.modelValue === this.trueValue;
    },

    classes() {
      return [
        {
          'has-error': this.error,
        },
      ];
    },
  },
  methods: {
    updateInput(event) {
      let isChecked = event.target.checked;
      if (this.modelValue instanceof Array) {
        let newValue = [...this.modelValue];
        if (isChecked) {
          newValue.push(this.value);
        } else {
          newValue.splice(newValue.indexOf(this.value), 1);
        }
        this.$emit('change', newValue);
      } else {
        this.$emit('change', isChecked ? this.trueValue : this.falseValue);
      }
    }
  }
};
</script>

<style lang="scss">
/* Customize the label (the wrapper) */
.wrapper {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 6px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 400;
  font-size: 12px;
  color: $base-0;

  &.has-error {
    color: $error;

    .checkmark {
      border-color: $error;
    }
  }
}

.v-checkbox__error-text {
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #E51133;
}


/* Hide the browser's default checkbox */
.wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: -4px;
  left: 0;
  height: 24px;
  width: 24px;
  border-radius: 2px;
  border: 1px solid $base-10;
  background-color: $base-10;
}

/* On mouse-over, add a grey background color */
.wrapper:hover input ~ .checkmark {
  background-color: $base-10;
}

/* When the checkbox is checked, add a blue background */
.wrapper input:checked ~ .checkmark {
  background-color: $base-0;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.wrapper input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.wrapper .checkmark:after {
  left: 7px;
  top: 0;
  width: 7px;
  height: 15px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
